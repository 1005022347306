<!-- 订单列表 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <div v-if="userLoading" class="loading-class">
        <van-loading type="spinner" size="24px" />
      </div>
      <div v-else class="user-module">
        <div class="user-avatar">
          <van-image
            width="50px"
            height="50px"
            fit="cover"
            radius="5"
            :src="userInfo.avatar!=null&&userInfo.avatar.length>0?userInfo.avatar:require('@/assets/image/organ-logo.png')"
          />
        </div>
        <div class="user-info">
          <div>
            <span class="user-name">{{userInfo.user_name}}</span>
            <span>{{userInfo.branch_name}}</span>
          </div>
          <div>
            {{userInfo.organ_name}}
          </div>
        </div>
        <div class="login-out">
          <van-button
            plain
            hairline
            type="primary"
            style="height:25px;"
            @click="loginOut"
          >
            退出
          </van-button>
        </div>
      </div>
    </header>
    <section class="page-info">
      <div class="pay-state-tabs">
        <div class="pay-state-item" @click="goOrderList(1)">
          <van-icon name="gold-coin" size="30px" color="#666" />
          <div class="state-content">支付订单</div>
        </div>
        <div class="pay-state-item" @click="goOrderList(2)">
          <van-icon name="bill" size="30px" color="#666" />
          <div class="state-content">退款订单</div>
        </div>
      </div>
      <div class="pay-detail-tabs">
        <van-tabs v-model:active="active" color="#409EFF" title-active-color="#409EFF">
          <van-tab title="今日">
            <BillStat v-if="active==0" :activate="active" />
          </van-tab>
          <van-tab title="本月">
            <BillStat v-if="active==1" :activate="active" />
          </van-tab>
          <van-tab title="全部">
            <BillStat v-if="active==2" :activate="active" />
          </van-tab>
        </van-tabs>
      </div>
      <div class="last-module">
        <div>
          最近缴费
        </div>
        <div class="last-all" @click="goOrderList(1)">
          全部
          <van-icon name="arrow" size="14px" />
        </div>
      </div>
      <div class="recently-paid">
        <div class="paid-card" v-for="item in orderList" :key="item.order_id" @click="goDetail(item.order_sn)">
          <div class="meal-tag">
            <div v-if="item.shop_type==1">
              <div v-if="item.meal_mode==1">
                早餐
              </div>
              <div v-else-if="item.meal_mode==2">
                午餐
              </div>
              <div v-else-if="item.meal_mode==3">
                晚餐
              </div>
              <div v-else>
                其他
              </div>
            </div>
            <div v-else-if="item.shop_type==3">
              膳食套餐
            </div>
            <div v-else>
              预制菜
            </div>
          </div>
          <div class="click-tag">
            点击查看详情
          </div>
          <div class="paid-item">
            支付时间: <span>{{getTimeYMDHMS(item.pay_dt)}}</span>
          </div>
          <div class="paid-item">
            就餐日期: <span>{{ getTimeYMD(item.order_dt) }}</span>
          </div>
          <div class="paid-item">
            订餐时间: <span>{{ getTimeYMDHMS(item.insert_dt) }}</span>
          </div>
          <div class="paid-item">
            支付金额: <span class="pay-num">{{item.amount}}</span><span>元</span>
          </div>
          <div class="paid-item">
            业务编号: <span>{{item.pay_sn}}</span>
          </div>
          <div class="paid-item">
            订单编号: <span>{{item.order_sn}}</span>
          </div>
          <div class="paid-item-last">
            <div v-if="item.state==1 && item.pay_state==1 && item.refund_state!=2" style="color:#1989fa;width:100%">
              订单状态：{{ item.delivery_state_str }}
            </div>
            <div v-else-if="item.state!=1" style="width:100%">
              订单状态：已取消
            </div>
            <div v-else-if="item.refund_state==2" style="color:#FF3333;width:100%">
              订单状态：已退款（原路退回）
            </div>
            <div v-else-if="item.state==1 && item.pay_state==0 && item.delivery_state==0" style="color:#FF3333;width:100%">
              订单状态：{{ item.pay_state_str }}
            </div>
            <div v-else  style="color:#FF3333;width:100%">
              订单状态：{{ item.pay_state_str }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { getStorage, setStorage, delStorage } from '@/utils/localStorageUtils'
import CommonData from '@/utils/commonMgrData'
import { getTimeYMD, getTimeYMDHMS } from "@/utils/util"
import BillStat from "./component/billStat.vue"

import login from "@/api/ofsMgr/login"
import organ from "@/api/ofsMgr/organ"
import ofsMgr from "@/api/ofsMgr/ofsMgr"

export default {
  name: "orderMgrHome",
  components: {
    BillStat
  },
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      getUserInfo()
      getPayOrderList()
    });
    const data = reactive({
      userInfo: {},
      userLoading: true,
      active: 0,
      orderList: []
    });
    const getUserInfo = () => {
      var post_data = {
        user_id: localStorage.getItem(CommonData.KeyStorages.KEY_MGR_USER_ID)
      }
      organ.userInfo(post_data).then(res=> {
        data.userInfo = res.data
        setStorage(CommonData.KeyStorages.KEY_MGR_USER_NAME, res.data.user_name)
        data.userLoading = false
      })
    }
    const getPayOrderList = () => {
      const post_data = {
        page_index: 1,
        page_size: 5,
        pay_state: 1,
        canteen_id: getStorage(CommonData.KeyStorages.KEY_MGR_CANTEEN_ID)
      }
      ofsMgr.orderList(post_data).then(res => {
        console.log(res)
        data.orderList = res.data.data
      })
    }
    const loginOut = () => {
      console.log('loginOut')
      login.loginOut({}).then(res => {
        // 清除token
        delStorage(CommonData.KeyStorages.KEY_MGR_USER_TOKEN)
        $router.replace({
          path: '/order-mgr-login'
        })
      })
    }
    const goOrderList = (type) => {
      console.log(type)
      const params = {
        type: type
      }
      $router.push({
        name: "orderMgrList",
        query: params
      })
    }
    const goDetail = (order_sn) => {
      const params = {
        order_sn: order_sn
      }
      $router.push({
        name: "orderMgrInfo",
        query: params
      })
    }

    return {
      ...toRefs(data),
      loginOut,
      goOrderList,
      getTimeYMDHMS,
      getTimeYMD,
      goDetail
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.loading-class {
  height: 50px;
  width: 100%;
  text-align: center;
}
.page-main {
  width: 100%;
  background: #eee;
  .page-header {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    .user-module {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      .user-info {
        margin-left: 10px;
        font-size: 14px;
        line-height: 20px;
        .user-name {
          font-size: 16px;
          font-weight: bold;
          margin-right: 10px;
        }
      }
      .login-out {
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }
  .page-info {
    width: 100%;
    .pay-state-tabs {
      margin: 10px;
      padding: 10px;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      .pay-state-item {
        text-align: center;
        margin-right: 20px;
        .state-content {
          color: #666;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .pay-detail-tabs {
      margin: 10px;
      padding: 10px;
      border-radius: 10px;
      background: #fff;
    }
    .last-module {
      padding: 10px;
      font-size: 14px;
      color: #666;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      .last-all {
        display: flex;
        align-items: center;
      }
    }
    .recently-paid {
      padding: 10px;
      .paid-card {
        margin-bottom: 10px;
        background: #FFF;
        padding: 30px 10px 10px 10px;
        border-radius: 12px;
        position: relative;
        .meal-tag {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 14px;
          padding: 3px 10px;
          color: #fff;
          background: #99BBFF;
          border-radius: 12px 0 12px 0;
        }
        .click-tag {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 14px;
          padding: 3px 10px;
          color: #fff;
          background: #9F88FF;
          border-radius: 0 12px 0 12px;
        }
        .paid-item {
          margin-bottom: 5px;
          color: #666;
          font-size: 14px;
          line-height: 20px;
          .pay-num {
            font-size: 20px;
            color: #000;
            line-height: 20px;
            font-weight: bold;
          }
        }
        .paid-item-last {
          color: #666;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
