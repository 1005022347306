<!-- 账单统计 -->
<template>
  <div class="bill-stat-page-main">
    <div v-if="loading">
      <van-loading type="spinner" size="24px" />
    </div>
    <div v-else class="stat-main">
      <div class="stat-item">
        <div class="stat-num">
          {{stat.pay_num}}
        </div>
        <div class="stat-desc">
          支付订单
        </div>
      </div>
      <div class="stat-item">
        <div class="stat-num">
          {{stat.pay_amount}}
        </div>
        <div class="stat-desc">
          支付金额
        </div>
      </div>
      <div class="stat-item">
        <div class="stat-num">
          {{stat.refund_num}}
        </div>
        <div class="stat-desc">
          退款订单
        </div>
      </div>
      <div class="stat-item">
        <div class="stat-num">
          {{stat.refund_amount}}
        </div>
        <div class="stat-desc">
          退款金额
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { getStorage } from '@/utils/localStorageUtils'
import CommonData from '@/utils/commonMgrData'
import { dateToHMD, getTimeYMD } from "@/utils/util"
import ofsMgr from "@/api/ofsMgr/ofsMgr"

export default {
  name: "mgrBillState",
  components: {},
  props: {
    activate: {
      type: Int16Array,
      default: 0
    }
  },
  setup(props, setupContext) {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    const data = reactive({
      loading: true,
      stat: {}
    });

    onMounted(() => {
      //获取宣教内容
      getStatList()
    });

    const getStatList = () => {
      console.log(props.activate)
      var sdt = ''
      var edt = ''
      switch(props.activate) {
        case 0:
          var nowData = new Date()
          sdt = dateToHMD(nowData) + ' 00:00:00'
          edt = dateToHMD(nowData) + ' 23:59:59'
          break
        case 1:
          var nowData = new Date()
          sdt = getTimeYMD(nowData.setMonth(nowData.getMonth(), 1)) + ' 00:00:00'
          var nowData = new Date()
          edt = getTimeYMD(nowData.setMonth(nowData.getMonth()+1, 0)) + ' 23:59:59'
        default:
          break
      }
      const post_data = {
        sdt: sdt,
        edt: edt,
        canteen_id: getStorage(CommonData.KeyStorages.KEY_MGR_CANTEEN_ID)
      }
      ofsMgr.orderStat(post_data).then(res=> {
        data.stat = res.data
        data.loading = false
      })
    }

    return {
      ...toRefs(data),
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.bill-stat-page-main {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  .stat-main {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    .stat-item {
      text-align: center;
      font-size: 14px;
      color: #666;
      .stat-num {
        color: #000;
        margin-bottom: 5px;
      }
    }
  }
}

</style>
